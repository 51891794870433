import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout title="NOT FOUND" description="You just hit a route that doesn&#39;t exist... the sadness.">
    <SEO title="404: Not found" />
  </Layout>
);

export default NotFoundPage;
